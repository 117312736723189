import { lazy, useEffect } from "react";
import "./App.module.scss";
import { Route, Routes, Navigate, useNavigate } from "react-router-dom";
import { privateRoutes, routes, verifyRoutes, authRoutes } from "const/routes";
import { useDispatch, useSelector } from "react-redux";
import { getCurrentAuthStatus } from "reducer/user/selectors";
import WhiteTheme from "components/System/WhiteTheme/WhiteTheme";
import ScrollToTop from "components/System/Scroll/ScrollToTop";
import { REACT_APP_API } from "api";
import { NotSupport } from "pages/NotSupport";
import { ActionCreator } from "reducer/globalError/globalError";
import TicketErrorPopup from "components/Popups/TicketErrorPopup/TicketErrorPopup";
import { getGlobalErrorMessage } from "reducer/globalError/selectors";
import { ActionCreator as BasketActionCreator, Operation } from 'reducer/basket/basket';
import { AuthorizationStatus } from "const/const";
import { getAllGoodsInBasket, getUpdateGoodsInBasket } from "reducer/basket/selectors";
import { getActualGoodsUpdateData } from "components/Basket/Basket";
import AuroraBackground from "../AuroraBackground/AuroraBackground";

const News = lazy(() => import('components/News/News'));
const OneNews = lazy(() => import('components/News/OneNews/OneNews'));
const CalendarPage = lazy(() => import('../../pages/CalendarPage'));
const Basket = lazy(() => import('components/Basket/Basket'));
const NewPassPage = lazy(() => import('components/Auth/NewPass/NewPassPage'));
const VerifyPage = lazy(() => import('components/Auth/VerifyPage/VerifyPage'));
const Main = lazy(() => import('components/Main/Main'));
const SocialAuth = lazy(() => import('components/Auth/Social/SocialAuth'));
const NotFound = lazy(() => import('./NotFound/NotFound'));
const Event = lazy(() => import('components/Event/Event'));
const About = lazy(() => import('components/About/About'));
const Partners = lazy(() => import('components/Partners/Partners'));
const Corporate = lazy(() => import('pages/CorporateScreen/Corporate'));
const Feedback = lazy(() => import('components/Feedback/Feedback'));
const Ticket = lazy(() => import('components/MyEvents/Ticket/Ticket'));
const Thanks = lazy(() => import('components/Basket/Thanks/Thanks'));
const TokenPage = lazy(() => import('components/TokenPage/TokenPage'));
const BasketFillOutside = lazy(() => import('components/BasketFillOutside/BasketFillOutside'));
const Add = lazy(() => import('components/Add/Add'));
const Docs = lazy(() => import("docs/Docs"));
const ShopMainScreen = lazy(() => import("../../pages/ShopMainScreen/ShopMainScreen"));
const ProductScreen = lazy(() => import('../../pages/ProductScreen/ProductScreen'));
const Order = lazy(() => import('../MyOrders/Order/Order'));
const Category = lazy(() => import('../Shop/Category/Category'));
const Search = lazy(() => import('pages/Search/Search'));
const CertificateThanks = lazy(() => import('components/Basket/Thanks/CertificateThanks'));
const Results = lazy(() => import('pages/Results/Results'));
const NewYearScreen = lazy(() => import('pages/NewYearScreen/NewYearScreen'));
const ResultsTable = lazy(() => import('../../pages/ResultsTable/ResultsTable'));
const MerchScreen = lazy(() => import('../../pages/MerchScreen/MerchScreen'));
const Smi = lazy(() => import('pages/Smi'));
const LogisticsScreen = lazy(() => import('../../pages/LogisticsScreen/LogisticsScreen'));
const Exit = lazy(() => import('../Exit'));
const LoginLink = lazy(() => import('./LoginLink'));
const ClubsPage = lazy(() => import('pages/ClubsPage'));
const ClubPage = lazy(() => import('../../pages/ClubPage'));
const ClubResultPage = lazy(() => import('../../pages/ClubResultPage'));

const devMode = process.env.NODE_ENV === `development`;

const App = () => {
  const currentAuthStatus = useSelector((state) => getCurrentAuthStatus(state));
  const globalErrorMessage = useSelector((state) => getGlobalErrorMessage(state));
  const updateGoods = useSelector((state) => getUpdateGoodsInBasket(state));
  const basketGoods = useSelector((state) => getAllGoodsInBasket(state));
  const dispatch = useDispatch();
  const navigate = useNavigate();

  useEffect(() => {

    if (currentAuthStatus === AuthorizationStatus.AUTH) {
      const userObj = localStorage.getItem("userObj") ? JSON.parse(localStorage.getItem("userObj")) : {};
      if (!userObj.public_id) {
        localStorage.removeItem("token");
        navigate(routes.authpage.path);
      }
      localStorage.removeItem("basketGoods");

      if (JSON.parse(localStorage.getItem("NoAuthBasketGoods")) && JSON.parse(localStorage.getItem("NoAuthBasketGoods"))?.length > 0) {
        const goods = JSON.parse(localStorage.NoAuthBasketGoods);

        goods.forEach((el) => {
          if (el.selector === `shop`) dispatch(Operation.addShop(el, () => { }, false));
          else dispatch(Operation.addGoods(el, () => { }, false));
        });

        if (basketGoods?.length) {
          dispatch(Operation.updateUserBasketGoods(getActualGoodsUpdateData(basketGoods), () => { }));
          localStorage.removeItem("NoAuthBasketGoods");
          dispatch(Operation.getUserBasketGoods());
        }
      } else {
        dispatch(Operation.getUserBasketGoods());
      }

    } else {
      if (localStorage.basketGoods) {
        const goods = JSON.parse(localStorage.basketGoods);

        goods.forEach((el) => {
          if (el.selector === `shop`) dispatch(BasketActionCreator.addShop(el));
          else dispatch(BasketActionCreator.addGoods(el));
        });
      }
    }

  }, [currentAuthStatus, updateGoods]);


  useEffect(() => {
    if (window.MSCompatibleInfo != null && window.location.pathname !== routes.notsupport.path) {
      /*  navigation(routes.notsupport.path, { replace: true }) */
      window.location.href = `${REACT_APP_API}${routes.notsupport.path}`;
    }
  }, []);

  const hadleCloseGlobalErrorPopup = () => {
    dispatch(ActionCreator.setGlobalErrorMessage(""));
  };

  return (
    <>
      <AuroraBackground />
      {globalErrorMessage && <TicketErrorPopup
        buttonTitle={"Понятно"}
        title={globalErrorMessage}
        statePopupHandler={hadleCloseGlobalErrorPopup} />}

      <ScrollToTop />
      <WhiteTheme />
      <Routes>

        <Route
          path="/docs"
          element={devMode ? <Docs /> : <Navigate to={`/`} />}
        />

        {privateRoutes.map((route) => {
          return (
            <Route
              key={route.id}
              path={route.path}
              element={
                currentAuthStatus === route.status ? (
                  route.element
                ) : (
                  <Navigate to={route.pathRedirect} />
                )
              }
            />
          );
        })}

        {verifyRoutes.map((route) => {
          return (
            <Route
              key={route.id}
              path={route.path}
              element={<VerifyPage dispatchName={route.dispatch} />}
            />
          );
        })}

        {authRoutes.map((route) => {
          return (
            <Route
              key={route.id}
              path={`${route.net.path}/:id`}
              element={<SocialAuth object={route} />}
            />
          );
        })}

        <Route
          path={`${routes.basketFillOutside.path}/:id`}
          element={<BasketFillOutside />}
        />
        <Route
          path={routes.add.path}
          element={<Add />}
        />

        <Route path={`${routes.token.path}/:id`} element={<TokenPage />} />
        <Route path={routes.clubs.path} element={<ClubsPage />} />
        <Route path={`${routes.clubs.path}/:id`} element={<ClubPage />} />
        <Route path={`${routes.clubs.path}/:id/upload-result`} element={<ClubResultPage />} />
        <Route path={`${routes.myEvents.path}/ticket/:id`} element={<Ticket />} />
        <Route path={`${routes.myOrders.path}/:id`} element={<Order />} />
        <Route path={`${routes.event.path}/:id`} element={<Event />} />
        <Route path={`${routes.event.path}/:id/:format_public_id`} element={<Event />} />
        <Route path={`${routes.notsupport.path}`} element={<NotSupport />} />

        <Route path={`${routes.news.path}/:id`} element={<OneNews />} />
        <Route path={routes.news.path} element={<News />} />
        <Route path={routes.thanks.path} element={<Thanks />} />
        <Route
          path={routes.certificateThanks.path}
          element={<CertificateThanks />}
        />
        <Route path={routes.basket.path} element={<Basket />} />
        <Route path={routes.smi.path} element={<Smi />} />
        <Route path={routes.calendar.path} element={<CalendarPage />} />
        <Route
          path={`${routes.calendar.path}/:event_id`}
          element={<CalendarPage />}
        />
        <Route
          path={`${routes.calendar.path}/:event_id/:promocode`}
          element={<CalendarPage />}
        />
        <Route index element={<Main />} />
        <Route path={routes.main.path} element={<Main />} />
        <Route path={routes.about.path} element={<About />} />
        <Route path={routes.partners.path} element={<Partners />} />
        <Route path={routes.corporate.path} element={<Corporate />} />
        <Route path={routes.feedback.path} element={<Feedback />} />
        <Route path={routes.shop.path} element={<ShopMainScreen />} />
        <Route
          path={`${routes.category.path}/:category`}
          element={<Category />}
        />
        <Route
          path={`${routes.product.path}/:id`}
          element={<ProductScreen />}
        />
        <Route path={routes.search.path} element={<Search />} />
        <Route path={routes.loginLink.path} element={<LoginLink />} />
        <Route path={routes.exit.path} element={<Exit />} />
        <Route path={routes.merch.path} element={<MerchScreen />} />
        <Route path={routes.logistics.path} element={<LogisticsScreen />} />
        {/*         <Route path={routes.error.path} element={<NotFound />} /> */}
        <Route path={routes.newPassword.path} element={<NewPassPage />} />
        <Route path={routes.results.path} element={<Results />} />
        <Route path={routes.newyear.path} element={<NewYearScreen />} />
        {/*<Route  path={`${routes.results.path}/:event`} component={ResultsSeason} /> element={<ResultsSeason />}*/}
        <Route
          path={`${routes.results.path}/:event_city_id`}
          element={<ResultsTable />}
        />
        <Route path="*" element={<NotFound />} />
      </Routes>
    </>
  );
};

export default App;
