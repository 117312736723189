import { returnElementById } from './utils';

export const checkActualTicketsCount = (ticketFormats, goods, promocodes) => {
  const tickets = goods.filter((item) => item.selector === 'ticket');

  if (tickets.length === 0) {
    return true;
  }

  return tickets.every((ticket) => {
    if (ticket.team_all && ticket.fixed_count !== undefined) {
      ticket.count =
        ticket.fixed_count === false ? ticket.count : ticket.max_count;
    }

    const requiredTicketCount = ticket.count;
    const requiredFormat =
      ticketFormats[`${ticket.event_public_id}_${ticket.address}`];
    const ticketsLeft = requiredFormat?.tickets_left || {};

    const codeObj = returnElementById(promocodes, ticket);
    const kind = codeObj?.kind || ticket?.kind || 'athlete';

    return requiredTicketCount <= (ticketsLeft[kind] || 0);
  });
};

// function to get object for payment (tickets, shop, certificates) + ?delivery
export const getPaymentObject = (goods, promocodes, email) => {
  const sendGoods = {
    email,
    tickets: [],
    shop: [],
    // certificates: [],
    insurances: [],
  };

  goods.forEach((el) => {
    if (el.space) {
      sendGoods.register = true;
    }

    if (el.selector === 'shop') {
      const codeObj = returnElementById(promocodes, el);

      const promocode = codeObj?.code || codeObj?.promocode;

      const proportion = el?.proportions?.find((p) => p?.name === el?.size);

      let newShop = {
        promocode: promocode || null,
        proportion: { public_id: proportion?.public_id },
        product: { public_id: el.public_id },
        count: el.count,
      };

      if (el?.text) {
        newShop = {
          ...newShop,
          text: el.text,
        };
      }

      if (el?.delivery_public_id) {
        newShop = {
          ...newShop,
          delivery_public_id: el.delivery_public_id,
        };
      }

      sendGoods.shop.push(newShop);
    } else if (el.selector === 'insurance') {
      const codeObj = returnElementById(promocodes, el);

      const promocode = codeObj?.code || codeObj?.promocode;

      let newInsurance = {
        promocode: promocode || null,
        insurance: {
          public_id: el.public_id,
        },
        count: el.count,
        first_name: el.first_name,
        last_name: el.last_name,
        birth_date: el.birth_date,
        gender: el.gender,
        phone: el.phone,
        email: email,
        start_date: el.start_date,
      };

      if (el.second_name) {
        newInsurance = {
          ...newInsurance,
          second_name: el.second_name,
        };
      }

      sendGoods.insurances.push(newInsurance);
    } else if (el.selector === 'ticket') {
      const codeObj = returnElementById(promocodes, el);

      let idName = 'format_public_id',
        countName = 'ticket_count';

      if (el.isTeam) {
        idName = 'team_public_id';
        if (el.full) countName = 'team_count';
      }

      if (el.team_all) {
        if (el.fixed_count !== undefined) {
          el.count = el.fixed_count === false ? el.count : el.max_count;
        }
      }

      let newTicket = {
        event_public_id: el.event_public_id,
        abid: el.abid,
        [idName]: el.public_id,
        [countName]: el.count,
        insurance: el.insurance,
        promocode: codeObj ? codeObj.code : null,
        city_id: el.city.id,
      };
      if (el?.delivery_public_id) {
        newTicket = {
          ...newTicket,
          delivery_public_id: el.delivery_public_id,
        };
      }
      if (el?.license_file) {
        newTicket = {
          ...newTicket,
          license: el?.license,
        };
      }
      if (el?.info && el?.cluster) {
        newTicket = {
          ...newTicket,
          info: { ...el.info, birth_date: new Date(el.info.birth_date) },
          cluster: el?.cluster,
        };
      }
      if (el?.info && !el?.cluster) {
        newTicket = {
          ...newTicket,
          info: { ...el.info, birth_date: new Date(el.info.birth_date) },
        };
      }
      if (el?.select_field) {
        newTicket = {
          ...newTicket,
          select_field: el.select_field,
        };
      }
      sendGoods.tickets.push(newTicket);
    } /* else if (el.selector === 'certificate') {
      let idName = `format_public_id`,
        countName = `ticket_count`;

      if (el.isTeam) {
        idName = `team_public_id`;
        if (el.full) countName = `team_count`;
      }

      if (el.team_all) {
        if (el.fixed_count !== undefined) {
          el.count = el.fixed_count === false ? el.count : el.max_count;
        }
      }
      let newLicense = {
        event_public_id: el.event_public_id,
        [idName]: el.public_id,
        [countName]: el.count,
        insurance: el.insurance,
        promocode: null,
        city_id: el.city.id,
      };

      if (el?.delivery_public_id) {
        newLicense = {
          ...newLicense,
          delivery_public_id: el.delivery_public_id,
        };
      }

      sendGoods.certificates.push(newLicense);
    } */
  });

  return sendGoods;
};

// function to get discount object when certificate or tickets in basket
export const getGiftDiscount = (goods) => {
  /* const discountValues = {
    "5%": 0.05,
    "10%": 0.1,
    "14%": 0.14,
    "15%": 0.15,
    "20%": 0.2,
    "25%": 0.25,
  }; */

  let discountVolume = 0;
  let discountValue = 0;

  if (!goods.length) return 0;
  else {
    /*  const certificateGoods = goods.filter(
      (el) => el.selector === "certificate"
    ); */
    /* const costCertificates = certificateGoods.reduce(
      (acc, el) => acc + el.totalCost,
      0
    );
    const countAmountCertificates = certificateGoods.reduce(
      (acc, el) => acc + el.count,
      0
    ); */

    /*     const ticketGoods = goods.filter((el) => el.selector === "ticket"); */
    /*  const costTickets = ticketGoods.reduce((acc, el) => acc + el.totalCost, 0);
    const countAmountTickets = ticketGoods.reduce(
      (acc, el) => acc + el.count,
      0
    ); */

    // if (countAmountCertificates > 0) {
    //   switch (countAmountCertificates) {
    //     case 1:
    //       discountValue += Math.round(costCertificates * discountValues['5%'])
    //       discountVolume += 5;
    //       break;
    //     case 2:
    //       discountValue += Math.round(costCertificates * discountValues['10%'])
    //       discountVolume += 10;
    //       break;
    //     case 3:
    //       discountValue += Math.round(costCertificates * discountValues['15%'])
    //       discountVolume += 15;
    //       break;
    //     case 4:
    //       discountValue += Math.round(costCertificates * discountValues['20%'])
    //       discountVolume += 20;
    //       break;
    //     default:
    //       discountValue += Math.round(costCertificates * discountValues['25%'])
    //       discountVolume += 25;
    //       break;
    //   }
    // }

    // if (countAmountTickets >= 2) {
    //   discountValue += costTickets * discountValues['14%'];
    //   discountVolume += 14;
    // }

    return { volume: `${discountVolume}%`, value: discountValue };
  }
};
